import { AnimatePresence, motion } from 'framer-motion';
import React, { useMemo, useState, useEffect } from 'react';
import { getContrastColor } from '../../utils/colors';
import API from '../../utils/service';
// import Image from '../Common/Image';
import QRCode from 'react-qr-code';
// import { SlideItemV2Props } from './types';

const SlideItemLandscape = ({
  headerImage,
  headerTextEN,
  headerTextFR,
  bodyHeaderEN,
  bodyHeaderFR,
  bodyTextEN,
  bodyTextFR,
  bodyImage,
  logoImage,
  qrCodeURLEN,
  qrCodeURLFR,
  qrCodeHeaderEN,
  qrCodeHeaderFR,
  qrCodeSubHeaderEN,
  qrCodeSubHeaderFR,
  id,
  brandingColor,
  
}) => {
  const backgroundColor = brandingColor || '#eda02c';
  const shouldShowQrCodeFR = qrCodeURLFR;
  const shouldShowFooterFR = shouldShowQrCodeFR || logoImage || qrCodeHeaderFR || qrCodeSubHeaderFR;
  const bodyTextColor = getContrastColor(backgroundColor);
  const headerType = useMemo(() => {
    if (headerTextFR && headerImage === null) return 'textOnly';
    if (headerImage && headerTextFR === null) return 'imageOnly';
    return 'default';
  }, [headerImage, headerTextFR]);

  console.log(headerTextFR);
  const [englishHeader, isHeaderEnglish] = useState(false);
  const [englishBody, isBodyEnglish] = useState(false);
  const [englishFooter, isFooterEnglish] = useState(false);

  const [titleColor, setTitleColor] = useState('#000');

  const [headerImageBlob, setHeaderImageBlob] = useState(null);
  const [bodyImageBlob, setBodyImageBlob] = useState(null);
  const [logoImageBlob, setLogoImageBlob] = useState(null);

  const QrCode = ({ value = '', size = 256 }) =>{
    return <QRCode value={value} size={size} />;
  };

  setInterval(() => {
    isHeaderEnglish(true);
    isBodyEnglish(true);
    isFooterEnglish(true);
  }, 15000);

  useEffect(() => {
    const getImage = async () => {
      // Header Image Generation
      const preHeaderImage = await API.getImageUrl(headerImage);
      // console.log(preHeaderImage);
        // create a url for the image
      const headerImageUrl = URL.createObjectURL(preHeaderImage);
      // console.log(headerImageUrl);
      setHeaderImageBlob(headerImageUrl);
      
      // Body Image Generation
      const preBodyImage = await API.getImageUrl(bodyImage);
      // console.log(preBodyImage);
        // create a url for the image
      const preBodyImageUrl = URL.createObjectURL(preBodyImage);
      // console.log(headerBodyUrl);
      setBodyImageBlob(preBodyImageUrl);
      
      // Body Image Generation
      const preFooterImage = await API.getImageUrl(logoImage);
      console.log(preBodyImage);
        // create a url for the image
      const preFooterImageUrl = URL.createObjectURL(preFooterImage);
      console.log(preFooterImageUrl);
      setLogoImageBlob(preFooterImageUrl);
    }

    getImage()
      .catch((err) => {
          console.log(err);
      }
      );
  }, [headerImage, bodyImage]);


  return (
    <section
      style={{ background: 'url(\'/images/curves-landscape.svg\')' }}
      className="w-full p-2 bg-cover bg-no-repeat bg-center overflow-hidden"
    >
      <AnimatePresence>
        <motion.div
          key={id}
          animate={{ y: 0, opacity: 1 }}
          initial={{ y: -100, opacity: 0 }}
          transition={{ duration: 0.5 }}
        >

          <header
            className="w-full h-[15.75rem] flex items-center justify-center rounded-b-[3.5rem] px-[7rem] py-16"
            style={
            {
              backgroundColor: brandingColor || '',
            }
          }
          >
            {/* render both image and text */}
            {
            headerType === 'default' && (
              <div className="flex items-center w-full">
                <img
                  src={headerImageBlob || ''}
                  className="w-[8.125rem] h-[8.125rem] object-contain mr-16"
                />
                <h4
                  className="text-[80px] font-semibold leading-none"
                  style={{
                    color: 'white',
                  }}
                >
                  {headerTextFR}
                </h4>
              </div>
            )
          }

            {/* render only text */}
            {
            headerType === 'textOnly' && (
              <h4
                className="text-[80px] font-semibold text-center text-white"
              >
                {headerTextFR}
              </h4>
            )
          }

            {/* render only image */}
            {
            headerType === 'imageOnly' && (
              <img
                src={headerImageBlob || ''}
                className="w-64 object-contain"
              />
            )
          }
          </header>

        </motion.div>
      </AnimatePresence>
      <main className="flex w-full">
        <div className="left w-1/2 flex flex-col">
          {bodyImage && (
            <img
            src={bodyImageBlob || ''}
            className="rounded-[3.5rem] mt-2  w-full h-[33.5rem] object-contain"
            />
          )}
          {shouldShowFooterFR && (
          <footer className="bg-white w-full text-black px-20 py-6 flex items-center justify-center min-h-[15rem] rounded-t-[3.5rem] mt-2">
            {logoImage && (
            <img
              src={logoImageBlob}
              className="w-[9.37rem] mr-8"
            />
            )}
            <div className={`w-full flex items-center ${shouldShowQrCodeFR ? 'justify-center' : 'justify-start'}`}>
            {shouldShowQrCodeFR && <QrCode value={qrCodeURLFR} size={160} />}
              {englishFooter ? qrCodeHeaderEN && (
                <div className="text-[32px] ml-20  text-left justify-start">
                <p className="">{qrCodeHeaderEN}</p>
                  <p className="text-[44px] font-semibold leading-none">
                    {qrCodeSubHeaderEN}
                  </p>
                </div>
              ) : qrCodeHeaderFR && (
                <div className="text-[32px] ml-20 text-left justify-start">
                  <p className="">{qrCodeHeaderFR}</p>
                  <p className="text-[44px] font-semibold leading-none">
                    {qrCodeSubHeaderFR}
                  </p>
                </div>
              )}
            </div>
          </footer>
          )}
        </div>
        <div className="right w-1/2 flex items-start relative">
          <div className="absolute flex items-center top-20 -translate-x-1/2">
            {
              [1, 2, 3, 4, 5].map((i) => (
                <svg
                  width="18"
                  height="36"
                  viewBox="0 0 18 36"
                  className="mr-8"
                  fill="none"
                  key={i}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2 2L15.1716 15.1716C16.7337 16.7337 16.7337 19.2663 15.1716 20.8284L2 34" stroke="white" strokeWidth="3" />
                </svg>
              ))
            }
          </div>
          <div className="absolute flex items-center bottom-12 right-12">
            {
              [1, 2].map((i) => (
                <svg
                  width="18"
                  height="36"
                  viewBox="0 0 18 36"
                  className="mr-8"
                  fill="none"
                  key={i}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2 2L15.1716 15.1716C16.7337 16.7337 16.7337 19.2663 15.1716 20.8284L2 34" stroke="white" strokeWidth="3" />
                </svg>
              ))
            }
          </div>
          {bodyTextFR && (
          <AnimatePresence>
            <motion.div
              data-testid="slide-body__text"
              animate={{ opacity: 1, y: 0 }}
              initial={{ opacity: 0, y: 30 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className="justify-center flex flex-col items-start w-full mt-30"
            >
              <p
                className="text-white text-[80px] leading-tight p-5 uppercase"
                style={{ color: 'white' }}
              >
                {englishBody === true ? bodyHeaderEN : bodyHeaderFR}
              </p>
              <p
                className="text-white text-[70px] leading-tight p-5 uppercase"
                style={{ color: 'white' }}
                >
                {englishBody === true ? bodyTextEN : bodyTextFR}
              </p>
            </motion.div>
          </AnimatePresence>
          )}
        </div>
      </main>

    </section>
  );
};

export default SlideItemLandscape;
