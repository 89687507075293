const API = {
    retrieveCollections(item) {
      return fetch(`${process.env.REACT_APP_API_URL}/collections/`).then(
        (r) => r.json()
      );
    },
    retrieveItems(collectionId) {
      return fetch(`${process.env.REACT_APP_API_URL}/items/${collectionId}`).then(
        (r) => r.json()
      );
    },
    retrieveWaterTips(item) {
      return fetch(`${process.env.REACT_APP_API_URL}/items/Water_Tips`).then(
        (r) => r.json()
      );
    },
    retrieveWaterTrivia(item) {
      return fetch(`${process.env.REACT_APP_API_URL}/items/Water_Trivia`).then(
        (r) => r.json()
      );
    },
    retrieveWasteTips(item) {
      return fetch(`${process.env.REACT_APP_API_URL}/items/Waste_Tips`).then(
        (r) => r.json()
      );
    },
    retrieveWasteTrivia(item) {
      return fetch(`${process.env.REACT_APP_API_URL}/items/Waste_Trivia`).then(
        (r) => r.json()
      );
    },
    retrieveEnergyTips(item) {
      return fetch(`${process.env.REACT_APP_API_URL}/items/Energy_Tips`).then(
        (r) => r.json()
      );
    },
    retrieveEnergyTrivia(item) {
      return fetch(`${process.env.REACT_APP_API_URL}/items/Energy_Trivia`).then(
        (r) => r.json()
      );
    },
    retrieveGeneral(item) {
      return fetch(`${process.env.REACT_APP_API_URL}/items/General_Template`).then(
        (r) => r.json()
      );
    },
    retrieveEmergency(item) {
      return fetch(`${process.env.REACT_APP_API_URL}/items/Real_Time_Emergency_Messaging`).then(
        (r) => r.json()
      );
    },
    retrieveBranding(item) {
      return fetch(`${process.env.REACT_APP_API_URL}/items/Branding_Color`).then(
        (r) => r.json()
      );
    },
    retrieveRefreshToken(item) {
      return fetch(`${process.env.REACT_APP_API_URL}/items/Refresh_State`).then(
        (r) => r.json()
      );
    },
    async getImageUrl(id) {
      return id
        ? await fetch(`${process.env.REACT_APP_API_URL}/assets/${id}`).then(
            async (it) => await it.blob()
          )
        : "";
    },
  };
  
  export default API;
  