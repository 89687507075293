import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SlideData from './components/Greenfeeds/SlideData';
import GeneralSlideData from './components/General/SlideData';
import EmergencySlideData from './components/Emergency/SlideData';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/greenfeeds/" exact element={<SlideData />} />
          <Route path="/greenfeeds/:collectionId" exact element={<SlideData />} />
          <Route path="/greenfeeds/:collectionId/:itemId" exact element={<SlideData />} />

          <Route path="/general/" exact element={<GeneralSlideData />} />
          <Route path="/general/:collectionId" exact element={<GeneralSlideData />} />

          <Route path="/emergency/" exact element={<EmergencySlideData />} />
          <Route path="/emergency/:collectionId" exact element={<EmergencySlideData />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
