import API from "../../utils/service";
import { dbgreenfeeds, dbgeneral, dbuservariables } from "./db";
import {useState, useEffect} from 'react';

export default function storeData () {
    //get API retrieveWaterTips and map through it and set the state for each item
    API.retrieveWaterTips().then((response) => {
        console.log('update', response.data[0]);
        // map each item and console log it
        response.data.map((item) => {
            // add each item to the database
            dbgreenfeeds.watertips.bulkPut([
                {
                    id: item.id,
                    tipTitleEN: item.EN_Tip_Title,
                    tipTitleFR: item.FR_Tip_Title,
                    tipEN: item.EN_Tip,
                    tipFR: item.FR_Tip,
                    tipImage: item.Tip_Image,
                    qrCodeURLEN: item.EN_QR_Code_URL,
                    qrCodeURLFR: item.FR_QR_Code_URL,
                    qrCodeHeaderEN: item.EN_CTA_Header,
                    qrCodeHeaderFR: item.FR_CTA_Header,
                    qrCodeSubHeaderEN: item.EN_CTA_Subheader,
                    qrCodeSubHeaderFR: item.FR_CTA_Subheader,
                },
            ]);

        });
    });
    API.retrieveEnergyTips().then((response) => {
        // map each item and console log it
        response.data.map((item) => {
            console.log(item);
            // add each item to the database
            dbgreenfeeds.energytips.bulkPut([
                {
                    id: item.id,
                    tipTitleEN: item.EN_Tip_Title,
                    tipTitleFR: item.FR_Tip_Title,
                    tipEN: item.EN_Tip,
                    tipFR: item.FR_Tip,
                    tipImage: item.Tip_Image,
                    qrCodeURLEN: item.EN_QR_Code_URL,
                    qrCodeURLFR: item.FR_QR_Code_URL,
                    qrCodeHeaderEN: item.EN_CTA_Header,
                    qrCodeHeaderFR: item.FR_CTA_Header,
                    qrCodeSubHeaderEN: item.EN_CTA_Subheader,
                    qrCodeSubHeaderFR: item.FR_CTA_Subheader,
                },
            ]);
        });
    });
    API.retrieveWasteTips().then((response) => {
        // map each item and console log it
        response.data.map((item) => {
            console.log(item);
            // add each item to the database
            dbgreenfeeds.wastetips.bulkPut([
                {
                    id: item.id,
                    tipTitleEN: item.EN_Tip_Title,
                    tipTitleFR: item.FR_Tip_Title,
                    tipEN: item.EN_Tip,
                    tipFR: item.FR_Tip,
                    tipImage: item.Tip_Image,
                    qrCodeURLEN: item.EN_QR_Code_URL,
                    qrCodeURLFR: item.FR_QR_Code_URL,
                    qrCodeHeaderEN: item.EN_CTA_Header,
                    qrCodeHeaderFR: item.FR_CTA_Header,
                    qrCodeSubHeaderEN: item.EN_CTA_Subheader,
                    qrCodeSubHeaderFR: item.FR_CTA_Subheader,
                    
                },
            ]);
        });
    });
    API.retrieveGeneral().then((response) => {
        // map each item and console log it
        response.data.map((item) => {
            console.log(item);
            // add each item to the database
            dbgeneral.general.bulkPut([
                {
                    id: item.id,
                    headerImage: item.Header_Image,
                    headerTextEN: item.EN_Header_Text,
                    headerTextFR: item.FR_Header_Text,
                    bodyHeaderEN: item.EN_Body_Header,
                    bodyHeaderFR: item.FR_Body_Header,
                    bodyTextEN: item.EN_Body_Text,
                    bodyTextFR: item.FR_Body_Text,
                    bodyImage: item.Body_Image,
                    logoImage: item.Logo_Image,
                    qrCodeURLEN: item.EN_QR_Code_URL,
                    qrCodeURLFR: item.FR_QR_Code_URL,
                    qrCodeHeaderEN: item.EN_CTA_Header,
                    qrCodeHeaderFR: item.FR_CTA_Header,
                    qrCodeSubHeaderEN: item.EN_CTA_Subheader,
                    qrCodeSubHeaderFR: item.FR_CTA_Subheader,
                    lastUpdated: item.Last_Updated,
                },
            ]);
        });
    });
    API.retrieveEmergency().then((response) => {
        // map each item and console log it
        response.data.map((item) => {
            console.log(item);
            // add each item to the database
            dbgeneral.emergency.bulkPut([
                {
                    id: item.id,
                    headerImage: item.Header_Image,
                    headerTextEN: item.EN_Header_Text,
                    headerTextFR: item.FR_Header_Text,
                    bodyHeaderEN: item.EN_Body_Header,
                    bodyHeaderFR: item.FR_Body_Header,
                    bodyTextEN: item.EN_Body_Text,
                    bodyTextFR: item.FR_Body_Text,
                    bodyImage: item.Body_Image,
                    logoImage: item.Logo_Image,
                    qrCodeURLEN: item.EN_QR_Code_URL,
                    qrCodeURLFR: item.FR_QR_Code_URL,
                    qrCodeHeaderEN: item.EN_CTA_Header,
                    qrCodeHeaderFR: item.FR_CTA_Header,
                    qrCodeSubHeaderEN: item.EN_CTA_Subheader,
                    qrCodeSubHeaderFR: item.FR_CTA_Subheader,
                    isEmergency: item.Emergency_Override,
                },
            ]);
        });
    });
    API.retrieveBranding().then((response) => {
        // map each item and console log it
        console.log('branding' + response.data.Branding_Color);
        // response.data.map((item) => {
        //     console.log(item);
        //     // add each item to the database
            dbuservariables.variables.bulkPut([
                {
                    id: response.data.id,
                    brandingcolor: response.data.Branding_Color,
                },
            ]);
        // });
    });
    API.retrieveRefreshToken().then((response) => {
        // map each item and console log it
        console.log(response.data.Refresh_Token);
        // response.data.map((item) => {
        //     console.log(item);
        //     // add each item to the database
            dbuservariables.refreshToken.bulkPut([
                {
                    id: response.data.id,
                    refreshToken: response.data.Refresh_Token,
                },
            ]);
        // });
    });
};
 
    