// db.js
import Dexie from 'dexie';

export const dbgreenfeeds = new Dexie('greenfeeds');
dbgreenfeeds.version(1).stores({
    watertips: '++id, tipTitleEN, tipTitleFR, tipEN, tipFR, tipImage, qrCodeURLEN, qrCodeURLFR, qrCodeHeaderEN, qrCodeHeaderFR, qrCodeSubHeaderEN, qrCodeSubHeaderFR',
    energytips: '++id, tipTitleEN, tipTitleFR, tipEN, tipFR, tipImage, qrCodeURLEN, qrCodeURLFR, qrCodeHeaderEN, qrCodeHeaderFR, qrCodeSubHeaderEN, qrCodeSubHeaderFR',
    wastetips: '++id, tipTitleEN, tipTitleFR, tipEN, tipFR, tipImage, qrCodeURLEN, qrCodeURLFR, qrCodeHeaderEN, qrCodeHeaderFR, qrCodeSubHeaderEN, qrCodeSubHeaderFR',
});

export const dbgeneral = new Dexie('general');
dbgeneral.version(1).stores({
    general: '++id, headerImage, headerTextEN, headerTextFR, bodyHeaderEN, bodyHeaderFR, bodyTextEN, bodyTextFR, bodyImage, logoImage, qrCodeURLEN, qrCodeURLFR, qrCodeHeaderEN, qrCodeHeaderFR, qrCodeSubHeaderEN, qrCodeSubHeaderFR, lastUpdated',
    emergency: '++id, headerImage, headerTextEN, headerTextFR, bodyHeaderEN, bodyHeaderFR, bodyTextEN, bodyTextFR, bodyImage, logoImage, qrCodeURLEN, qrCodeURLFR, qrCodeHeaderEN, qrCodeHeaderFR, qrCodeSubHeaderEN, qrCodeSubHeaderFR, isEmergency',
});

export const dbuservariables = new Dexie('variables');
dbuservariables.version(1).stores({
    variables: '++id, brandingcolor',
    refreshToken: '++id, refreshToken',
});


