import { AnimatePresence, motion } from 'framer-motion';
import React, { useMemo, useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
// import { getContrastColor } from '../../utils';
// import Image from '../Common/Image';
// import QrCode from '../Common/QrCode';
import QRCode from 'react-qr-code';
import API from '../../utils/service';
// import { GreenFeedsSlideProps } from './types';

const SlideItemLandscape = ({
    tipTitleFR,
    tipTitleEN,
    tipFR,
    tipEN,
    tipImage,
    logoImage,
    qrCodeURLFR,
    qrCodeURLEN,
    qrCodeHeaderEN,
    qrCodeHeaderFR,
    qrCodeSubHeaderEN,
    qrCodeSubHeaderFR,
    collectionName,
    id,
}) => {
  const backgroundColor = '#000';
  const shouldShowQrCodeFR = qrCodeURLFR;
  const shouldShowQrCodeEN = qrCodeURLEN;
  const shouldShowFooterFR = shouldShowQrCodeFR || logoImage || qrCodeHeaderFR || qrCodeSubHeaderFR;
//   const bodyTextColor = getContrastColor(backgroundColor);
  // const headerType = useMemo(() => {
  //   if (headerTextFR && headerImage === null) return 'textOnly';
  //   if (headerImage && headerTextFR === null) return 'imageOnly';
  //   return 'default';
  // }, [headerImage, headerTextFR]);
  const { collectionId, itemId } = useParams();

  const [englishHeader, isHeaderEnglish] = useState(false);
  const [englishBody, isBodyEnglish] = useState(false);
  const [englishFooter, isFooterEnglish] = useState(false);
  const [titleColor, setTitleColor] = useState('#000');

  const [imageBlob, setImageBlob] = useState(null);

  const QrCode = ({ value = '', size = 256 }) =>{
    return <QRCode value={value} size={size} />;
  };


  setInterval(() => {
    isHeaderEnglish(true);
    isBodyEnglish(true);
    isFooterEnglish(true);
  }, 15000);

  useEffect(() => {
    switch (collectionId) {
      case 'Water_Tips':
        setTitleColor('#55E1FF');
        break;
      case 'Energy_Tips':
        setTitleColor('#E2891C');
        break;
      case 'Waste_Tips':
        setTitleColor('#1BD421');
        break;
      default:
        setTitleColor('#fff');
        break;
    }
  }, [collectionId]);

  useEffect(() => {
    const getImage = async () => {
      const image = await API.getImageUrl(tipImage);
      console.log(image);
      // create a url for the image
      const imageUrl = URL.createObjectURL(image);
      console.log(imageUrl);
      setImageBlob(imageUrl);
    }

    getImage()
      .catch((err) => {
          console.log(err);
      }
      );
  }, [tipImage]);

  return (
    <section>
      <AnimatePresence>
        <motion.div
          key={id}
          animate={{ y: 0, opacity: 1 }}
          initial={{ y: -100, opacity: 0 }}
          transition={{ duration: 0.5 }}
        >

          <header className="w-full h-[10.75rem] flex items-center justify-center rounded-b-[3.5rem] px-[3rem] py-6">
            {/* render both image and text */}

            <div className="flex items-center w-full align-center">
              <h4
                className="text-[100px] leading-none uppercase greenfeeds-header tracking-wider"
                style={{
                  color: titleColor,
                }}
              >
                {englishHeader === true ? tipTitleEN : tipTitleFR}
              </h4>
            </div>
          </header>

        </motion.div>
      </AnimatePresence>
      <main className="flex w-full">
        <div className="left w-[57rem] flex flex-col pl-10">
        <img
            src={imageBlob}
            className="mt-2 mb-2 rounded-[3.5rem]"
          />
        </div>
        <div className="right w-1/2 flex items-center relative">
          {tipFR && (
          <AnimatePresence>
            <motion.div
              data-testid="slide-body__text"
              animate={{ opacity: 1, y: 0 }}
              initial={{ opacity: 0, y: 30 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className="w-full"
            >
              <p className="text-white text-[90px] text-left leading-tight h-[555px] pl-10 flex items-top justify-left uppercase greenfeeds-body">
                {englishBody === true ? tipEN : tipFR}
              </p>
            </motion.div>
          </AnimatePresence>
          )}
        </div>
      </main>
      {shouldShowFooterFR && (
        <footer className="absolute bottom-2 left-0 w-full p-10 pb-10">
          <div className=" bg-white w-full text-black px-20 py-6 flex items-center justify-center min-h-[10rem] rounded-[3.5rem]">
            {logoImage && (
              <img src={logoImage} className="w-[12rem]  "  />
              )}
            <div
              className={`w-full flex items-center ${
                shouldShowQrCodeFR ? 'justify-center' : 'justify-start'
              }`}
            >
              {englishFooter === true ? (
                shouldShowQrCodeEN && <QrCode value={qrCodeURLEN} size={160} />
              ) : (shouldShowQrCodeFR && <QrCode value={qrCodeURLFR} size={160} />)}
              {/* {shouldShowQrCodeFR && <QrCode value={qrCodeURLFR} size={160} />} */}
              {englishFooter ? qrCodeHeaderEN && qrCodeSubHeaderEN && (
                <div className="text-[32px] ml-20 max-w-[363px]">
                  <p className="">{qrCodeHeaderEN}</p>
                  <p className="text-[44px] font-semibold leading-none">
                    {qrCodeSubHeaderEN}
                  </p>
                </div>
              ) : qrCodeHeaderFR && qrCodeHeaderFR && (
                <div className="text-[32px] ml-20 max-w-[363px]">
                  <p className="">{qrCodeHeaderFR}</p>
                  <p className="text-[44px] font-semibold leading-none">
                    {qrCodeSubHeaderFR}
                  </p>
                </div>
              )}
            </div>
          </div>
        </footer>
      )}
    </section>
  );
};

export default SlideItemLandscape;
