// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ColorContrastChecker from 'color-contrast-checker';

const colorPalette = {
  dark: '#000000',
  light: '#ffffff',
};

const DEFAULT_BRANDING_COLOR = process.env.REACT_APP_DEFAULT_BRANDING_COLOR || '#FBC83C';

function getContrastColor(backgroundColor = '', foregroundColor = colorPalette.dark) {
  const colorContrastChecker = new ColorContrastChecker();

  if (colorContrastChecker.isLevelAAA(foregroundColor || '#ffffff', backgroundColor, 140)) return colorPalette.dark;
  return colorPalette.light;
}

export {
  DEFAULT_BRANDING_COLOR,
  getContrastColor, // eslint-disable-line import/prefer-default-export
};
