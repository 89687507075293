import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { preloadImage, useLocalStorage } from '../../utils/functions';
import SlideItemPortrait from "./Slide";
import { dbgeneral, dbuservariables } from "../../utils/cache/db";
import { useLiveQuery } from "dexie-react-hooks";
import StoreData from "../../utils/cache/store";
import SlideItemV2 from "./SlideItem";
import API from "../../utils/service";

const GeneralSlideData = (props) => {
  const [items, setItems] = useLocalStorage('general_items', []);
  const [lastUpdatedAt, setLastUpdatedAt] = useLocalStorage('last_updated_at', null);
  const [brandingColor, setBrandingColor] = useLocalStorage('branding_color', []);
  // const [refreshToken, setRefreshToken] = useLocalStorage('refresh_token', []);


  const { collectionId, itemId } = useParams();
  const [storedCurrentItemIndex, storeCurrentItemIndex] = useLocalStorage(
    'general_index',
    0
  );
  const [currentItemIndex] = useState(storedCurrentItemIndex + 1);
  const [currentItem, setCurrentItem] = useState({});

  const [headerImage, setHeaderImage] = useState(null);
  const [headerTextFR, setHeaderTextFR] = useState('');
  const [headerTextEN, setHeaderTextEN] = useState('');
  const [bodyHeaderEN, setBodyHeaderEN] = useState('');
  const [bodyHeaderFR, setBodyHeaderFR] = useState('');
  const [bodyTextEN, setBodyTextEN] = useState('');
  const [bodyTextFR, setBodyTextFR] = useState('');
  const [bodyImage, setBodyImage] = useState(null);
  const [logoImage, setLogoImage] = useState(null);
  const [qrCodeURLEN, setQrCodeURLEN] = useState('');
  const [qrCodeURLFR, setQrCodeURLFR] = useState('');
  const [qrCodeHeaderEN, setQrCodeHeaderEN] = useState('');
  const [qrCodeHeaderFR, setQrCodeHeaderFR] = useState('');
  const [qrCodeSubHeaderEN, setQrCodeSubHeaderEN] = useState('');
  const [qrCodeSubHeaderFR, setQrCodeSubHeaderFR] = useState('');
  // const [brandingcolor, setBrandingcolor] = useState('#090745');
  const [lastUpdated, setLastUpdated] = useState('');
  const [id , setId] = useState('');

  


  // using live query to get the data from the database
  // THIS IS GETTING DATA FROM THE INDEXEDDB
  // const watertips = useLiveQuery(() => dbgreenfeeds.watertips.toArray());
  // const energytips = useLiveQuery(() => dbgreenfeeds.energytips.toArray());
  // const wastetips = useLiveQuery(() => dbgreenfeeds.wastetips.toArray());
    const general = useLiveQuery(() => dbgeneral.general.toArray());
    const variables = useLiveQuery(() => dbuservariables.variables.toArray());
    const refreshTokenVar = useLiveQuery(() => dbuservariables.refreshToken.toArray());

    useEffect(() => {
      async function check() {
        API.retrieveRefreshToken()
          .then((response) => {
            const updatedAt = response.data.date_updated;
            
            if (!lastUpdatedAt || lastUpdatedAt !== updatedAt) {
              setLastUpdatedAt(updatedAt);
              localStorage.clear();
              StoreData();
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
  
      check()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  useEffect(() => {
  // Uncomment this to get the data from API
    // storeData();

    if (!general) {
      // LOADING OF THE DATA
      console.log("general is loading");
    } else {
      // ONCE DATA IS LOADED SET THE ITEMS
      console.log("got general");
      setItems(general);
      // console.log(items)
    }
    if (!variables) {
      // LOADING OF THE DATA
      console.log("variables is loading");
    } else {
      // ONCE DATA IS LOADED SET THE ITEMS
      console.log("got variables");
      // console.log(refreshTokenVar)
      setBrandingColor(variables[0].brandingcolor);
    }





    // THIS IS HANDLELING THE CURRENT ITEM VIA THE LOCAL STORAGE
    if (itemId) {
      const itemIndex = items.findIndex((item) => item.id == itemId);

      if (itemIndex !== -1) {
        setCurrentItem(items[itemIndex]);
        console.log(currentItem)
      //Setting state variables
      setHeaderImage(currentItem.headerImage);
      setHeaderTextFR(currentItem.headerTextFR);
      setHeaderTextEN(currentItem.headerTextEN);
      setBodyHeaderEN(currentItem.bodyHeaderEN);
      setBodyHeaderFR(currentItem.bodyHeaderFR);
      setBodyTextEN(currentItem.bodyTextEN);
      setBodyTextFR(currentItem.bodyTextFR);
      setBodyImage(currentItem.bodyImage);
      setLogoImage(currentItem.logoImage);
      setQrCodeURLEN(currentItem.qrCodeURLEN);
      setQrCodeURLFR(currentItem.qrCodeURLFR);
      setQrCodeHeaderEN(currentItem.qrCodeHeaderEN);
      setQrCodeHeaderFR(currentItem.qrCodeHeaderFR);
      setQrCodeSubHeaderEN(currentItem.qrCodeSubHeaderEN);
      setQrCodeSubHeaderFR(currentItem.qrCodeSubHeaderFR);
      setId(currentItem.id);
      }
    } else {
      // REFRESBING THE PAGE WILL GIVE NEXT ITEM
      let i = currentItemIndex;
      console.log(currentItem.EN_Tip_Title)

      // ID OF THE CURRENT ITEM
      if (!items[i]) {
        if (items.length && items[i % items.length]) {
          i = i % items.length;
        }
        if (items[0]) {
          i = 0;
        }
      }

      items.length && setCurrentItem(items[i]);

      storeCurrentItemIndex(i);
      // currentItem IS THE CURRENT ITEM FROM THE REFRESH STATE
      console.log(currentItem)

      //Setting state variables
      setHeaderImage(currentItem.headerImage);
      setHeaderTextFR(currentItem.headerTextFR);
      setHeaderTextEN(currentItem.headerTextEN);
      setBodyHeaderEN(currentItem.bodyHeaderEN);
      setBodyHeaderFR(currentItem.bodyHeaderFR);
      setBodyTextEN(currentItem.bodyTextEN);
      setBodyTextFR(currentItem.bodyTextFR);
      setBodyImage(currentItem.bodyImage);
      setLogoImage(currentItem.logoImage);
      setQrCodeURLEN(currentItem.qrCodeURLEN);
      setQrCodeURLFR(currentItem.qrCodeURLFR);
      setQrCodeHeaderEN(currentItem.qrCodeHeaderEN);
      setQrCodeHeaderFR(currentItem.qrCodeHeaderFR);
      setQrCodeSubHeaderEN(currentItem.qrCodeSubHeaderEN);
      setQrCodeSubHeaderFR(currentItem.qrCodeSubHeaderFR);
      setId(currentItem.id);
    }
  }, [currentItemIndex, items, itemId, collectionId, general, variables]);



    return (
        <div>
            <SlideItemV2 {...{
              headerImage,
              headerTextEN,
              headerTextFR,
              bodyHeaderEN,
              bodyHeaderFR,
              bodyTextEN,
              bodyTextFR,
              bodyImage,
              logoImage,
              qrCodeURLEN,
              qrCodeURLFR,
              qrCodeHeaderEN,
              qrCodeHeaderFR,
              qrCodeSubHeaderEN,
              qrCodeSubHeaderFR,
              id,
              brandingColor,
            }} />
        </div>
    );
};

export default GeneralSlideData;