import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { preloadImage, useLocalStorage } from '../../utils/functions';
import SlideItemPortrait from "./Slide";
import { dbgreenfeeds } from "../../utils/cache/db";
import { useLiveQuery } from "dexie-react-hooks";
import StoreData from "../../utils/cache/store";
import SlideItemV2 from "./SlideItem";
import API from "../../utils/service";

const SlideData = (props) => {
  const [lastUpdatedAt, setLastUpdatedAt] = useLocalStorage('last_updated_at', null);
  const { collectionId, itemId } = useParams();
  const [items, setItems] = useLocalStorage(`${collectionId}_items`, []);
  const [storedCurrentItemIndex, storeCurrentItemIndex] = useLocalStorage(
    `${collectionId}_items_index`,
    0
  );
  const [currentItemIndex] = useState(storedCurrentItemIndex + 1);
  const [currentItem, setCurrentItem] = useState({});

  const [tipTitleEN, setTipTitleEN] = useState('');
  const [tipTitleFR, setTipTitleFR] = useState('');
  const [tipEN, setTipEN] = useState('');
  const [tipFR, setTipFR] = useState('');
  const [tipImage, setTipImage] = useState('');
  const [qrCodeURLEN, setQrCodeURLEN] = useState('');
  const [qrCodeURLFR, setQrCodeURLFR] = useState('');
    
  const [qrCodeHeaderEN, setQrCodeHeaderEN] = useState('');
  const [qrCodeHeaderFR, setQrCodeHeaderFR] = useState('');
    
  const [qrCodeSubHeaderEN, setQrCodeSubHeaderEN] = useState('');
  const [qrCodeSubHeaderFR, setQrCodeSubHeaderFR] = useState('');
  const [id , setId] = useState('');
  


  // using live query to get the data from the database
  // THIS IS GETTING DATA FROM THE INDEXEDDB
  const watertips = useLiveQuery(() => dbgreenfeeds.watertips.toArray());
  const energytips = useLiveQuery(() => dbgreenfeeds.energytips.toArray());
  const wastetips = useLiveQuery(() => dbgreenfeeds.wastetips.toArray());

  useEffect(() => {
    async function check() {
      API.retrieveRefreshToken()
        .then((response) => {
          const updatedAt = response.data.date_updated;
          
          if (!lastUpdatedAt || lastUpdatedAt !== updatedAt) {
            setLastUpdatedAt(updatedAt);
            localStorage.clear();
            StoreData();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }

    check()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(collectionId);

  useEffect(() => {
    if (items.length === 0) {
      // storeData();
    }
    
    switch (collectionId) {
      case 'Water_Tips':
        if (!watertips) {
          // LOADING OF THE DATA
          console.log("watertips is loading");
        } else {
          // ONCE DATA IS LOADED SET THE ITEMS
          console.log("got watertips");
          setItems(watertips);
          console.log('WATER TIPS', items)
        }
        break;
      case 'Energy_Tips':
        if (!energytips) {
          // LOADING OF THE DATA
          console.log("energytips is loading");
        } else {
          // ONCE DATA IS LOADED SET THE ITEMS
          console.log("got energytips");
          setItems(energytips);
          console.log(items)
        }
        break;
      case 'Waste_Tips':
        if (!wastetips) {
          // LOADING OF THE DATA
          console.log("wastetips is loading");
        } else {
          // ONCE DATA IS LOADED SET THE ITEMS
          console.log("got wastetips");
          setItems(wastetips);
          console.log(items)
        }
        break;
      default:
        if (!watertips) {
          // LOADING OF THE DATA
          console.log("watertips is loading");
        } else {
          // ONCE DATA IS LOADED SET THE ITEMS
          console.log("got watertips");
          setItems(watertips);
          console.log(items)
        }
        break;
    }

    // THIS IS HANDLELING THE CURRENT ITEM VIA THE LOCAL STORAGE
    if (itemId) {
      const itemIndex = items.findIndex((item) => item.id == itemId);

      if (itemIndex !== -1) {
        setCurrentItem(items[itemIndex]);
        console.log(currentItem)
        setTipTitleEN(currentItem.tipTitleEN);
        setTipTitleFR(currentItem.tipTitleFR);
        setTipEN(currentItem.tipEN);
        setTipFR(currentItem.tipFR);
        setTipImage(currentItem.tipImage);
        setQrCodeURLEN(currentItem.qrCodeURLEN);
        setQrCodeURLFR(currentItem.qrCodeURLFR);
        setQrCodeHeaderEN(currentItem.qrCodeHeaderEN);
        setQrCodeHeaderFR(currentItem.qrCodeHeaderFR);
        setQrCodeSubHeaderEN(currentItem.qrCodeSubHeaderEN);
        setQrCodeSubHeaderFR(currentItem.qrCodeSubHeaderFR);
        setId(currentItem.id);
      }
    } else {
      // REFRESBING THE PAGE WILL GIVE NEXT ITEM
      let i = currentItemIndex;
      console.log(currentItem.EN_Tip_Title)

      // ID OF THE CURRENT ITEM
      if (!items[i]) {
        if (items.length && items[i % items.length]) {
          i = i % items.length;
        }
        if (items[0]) {
          i = 0;
        }
      }

      items.length && setCurrentItem(items[i]);

      storeCurrentItemIndex(i);
      // currentItem IS THE CURRENT ITEM FROM THE REFRESH STATE
      console.log(currentItem)
      setTipTitleEN(currentItem.tipTitleEN);
      setTipTitleFR(currentItem.tipTitleFR);
      setTipEN(currentItem.tipEN);
      setTipFR(currentItem.tipFR);
      setTipImage(currentItem.tipImage);
      setQrCodeURLEN(currentItem.qrCodeURLEN);
      setQrCodeURLFR(currentItem.qrCodeURLFR);
      setQrCodeHeaderEN(currentItem.qrCodeHeaderEN);
      setQrCodeHeaderFR(currentItem.qrCodeHeaderFR);
      setQrCodeSubHeaderEN(currentItem.qrCodeSubHeaderEN);
      setQrCodeSubHeaderFR(currentItem.qrCodeSubHeaderFR);
      setId(currentItem.id);
    }
  }, [currentItemIndex, items, itemId, collectionId, watertips, energytips, wastetips, currentItem]);


    return (
        <div>
            <h1> Data </h1>
            <SlideItemV2 {...{tipTitleEN, tipTitleFR, tipEN, tipFR, tipImage, qrCodeURLEN, qrCodeURLFR, qrCodeHeaderEN, qrCodeHeaderFR, qrCodeSubHeaderEN, qrCodeSubHeaderFR, id}} />
        </div>
    );
};

export default SlideData;