import React, { useMemo } from 'react';
import { useWindowSize } from 'react-use';
// eslint-disable-next-line import/no-cycle
import SlideItemLandscape from './SlideItemLandscape';
// eslint-disable-next-line import/no-cycle
import SlideItemPortrait from './SlideItemPortrait';

const SlideItemV2 = (props) => {
  const useWindow = useWindowSize();
  const slideMode = useMemo(() => (useWindow.width === 1080 ? 'portrait' : 'landscape'), [useWindow.width]);

  return (
    slideMode === 'portrait' ? (
      <SlideItemPortrait
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    ) : (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <SlideItemLandscape {...props} />
    )
  );
};

export default SlideItemV2;
